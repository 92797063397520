import { Box, SelectChangeEvent, styled } from "@mui/material";
import {
  DataGridProProps, GridCellModes, GridCellModesModel, GridColDef, GridEventListener, GridFilterModel, 
  GridProSlotsComponent, GridRenderCellParams, GridRenderEditCellParams, GridSortModel, 
  GridToolbarColumnsButton, GridToolbarExport, GridToolbarFilterButton, GridValidRowModel,
} from "@mui/x-data-grid-pro";
import colors from "../../colors";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { IconType } from "react-icons";
import CabDataGrid from "@CabComponents/CabDataGrid";


const getTogglableColumns = (cols: GridColDef[]) => {
  return cols.filter(c => !['', 'Options'].includes(c.field))
    .map(c => c.field);
};

const slotProps: DataGridProProps["slotProps"] = {
  columnsManagement: {
    getTogglableColumns: getTogglableColumns
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CRMGridColDef<T extends GridValidRowModel = any> = GridColDef<T> & {
  chipSelect?: boolean;
};

const Table = <T extends GridValidRowModel, >({
  columns, rows, onCellClick, page, pageSize, onPageChange, rowCount, filter, onSetFilter, sort, onSetSort, loading,
  interactive = true, paginate = true, defaultHiddenFields, hideTableButtons, onRowUpdate, actions, dataGridProps
}: {
  columns: readonly CRMGridColDef[];
  rows: readonly T[];
  page: number;
  pageSize: number;
  rowCount: number;
  onCellClick: GridEventListener<'cellClick'>;
  onPageChange: (page: number) => void;
  filter?: GridFilterModel;
  onSetFilter: (filter: GridFilterModel | undefined) => void;
  sort?: GridSortModel;
  onSetSort: (sort: GridSortModel | undefined) => void;
  loading: boolean;
  interactive?: boolean;
  paginate?: boolean;
  defaultHiddenFields?: string[];
  hideTableButtons?: boolean;
  onRowUpdate?: (updatedRow: T, originalRow: T) => Promise<T>,
  actions?: { name: string, key: string, onClick: (row: T) => void, icon: IconType }[];
  dataGridProps?: Partial<DataGridProProps<T>>;
}) => {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick: GridEventListener<'cellClick'> = useCallback((params, event, details) => {
    onCellClick(params, event, details);

    if (!params.isEditable) return;

    // Ignore portal
    if ('nodeType' in event.target && (event.target).nodeType === 1
      && !event.currentTarget.contains(event.target as Element)
    ) return;

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, [onCellClick]);

  const cols = useMemo(() => {
    const updatedColumns: GridColDef<T>[] = [
      ...columns.map(c => ({
        ...c,
        sortable: interactive,
        filterable: interactive,
        disableColumnMenu: !interactive,
        renderCell: c.renderCell
          ? c.renderCell
          : c.type === 'singleSelect' && c.chipSelect
            ? ((p: GridRenderCellParams) => <ChipSelectCell {...p} />)
            : undefined,
        renderEditCell: c.renderEditCell
          ? c.renderEditCell
          : c.type === 'singleSelect' && c.chipSelect
            ? ((p: GridRenderEditCellParams) => <ChipSelectEditCell {...p} />)
            : undefined
      })),
      { // this is an empty column which auto adjust to fill horizontal space
        field: '',
        headerName: '',
        // minWidth: 0, // this causes weird shifting in the headers when scrolling
        display: "flex",
        flex: 1,
        disableColumnMenu: true,
        resizable: false,
        hideable: false,
        filterable: false,
        sortable: false,
      },
    ];
    if (actions) {
      updatedColumns.push({
        field: 'Options',
        type: 'actions',
        disableColumnMenu: true,
        resizable: false,
        hideable: false,
        headerName: '',
        display: "flex",
        width: 50,
        filterable: false,
        sortable: false,
        renderCell: ({ row }) => (
          <CabCollapseMenu
            buttons={<>
              {actions.map(({ name, key, onClick, icon }) => (
                <StyledGroupButton 
                  key={key}
                  buttonType="text"
                  onClick={() => onClick(row)}
                  icon={<CabIcon Icon={icon} color={colors.black900}/>}
                >
                  {name}
                </StyledGroupButton>
              ))}
            </>}
            target={<CabIcon
              Icon={IoEllipsisHorizontal} alt="Edit"
              sx={{marginRight: 2, color: colors.black800, fontSize: 20}}
            />}
            popOverTitle=""
            popOverAnchorOrigin={{
              horizontal: -50,
              vertical: 30
            }}
          />
        ),
      });
    }
    
    return updatedColumns;
  }, [columns, interactive, actions]);

  const initialState = useMemo(() => ({
    pinnedColumns: { right: ['Options'] },
    pagination: { paginationModel: { page: 0, pageSize }},
    columns: {
      columnVisibilityModel: defaultHiddenFields?.map(f => ({ [f]: false })).reduce((a, b) => ({ ...a, ...b }), {})
    }
  }), [pageSize, defaultHiddenFields]);

  const paginationModel = useMemo(() => ({
    page,
    pageSize,
  }), [page, pageSize]);

  return (
    <CabDataGrid
      // apiRef={apiRef}
      initialState={initialState}
      // loading={loading || !isFullyInitialized}
      columns={cols}
      rows={rows}
      onCellClick={handleCellClick}
      pagination={paginate}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={((model) => onPageChange(model.page))}
      rowCount={rowCount}
      filterMode="server"
      filterModel={filter}
      onFilterModelChange={interactive ? onSetFilter : undefined}
      sortingMode="server"
      sortModel={sort}
      onSortModelChange={onSetSort}
      pageSizeOptions={[pageSize]}
      slots={(interactive && !hideTableButtons) ? tableSlots : undefined}
      slotProps={slotProps}
      loading={loading}
      hideFooter={!paginate}
      onCellModesModelChange={setCellModesModel}
      cellModesModel={cellModesModel}
      processRowUpdate={onRowUpdate}
      {...dataGridProps}
    />
  );
};

const tableSlots: Partial<GridProSlotsComponent> = {
  toolbar: () => (
    <Box display="flex" flexDirection="row" alignSelf="end" paddingTop={1}>
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      <GridToolbarExport 
        printOptions={{ disableToolbarButton: true }}
        // Can't specify fields/filename here because it's shared across multiple tables
        // csvOptions={{
        //   fields: ['name', 'role', 'primary_assistant'],
        //   fileName: `Cabinet_People_${new Date().toLocaleDateString('en')}_${new Date().toLocaleTimeString('en')}`
        // }}
      />
    </Box>
  )
};

export const ChipSelectCell = ({ formattedValue, value, colDef }: GridRenderCellParams) => (
  <CabToggleChip
    // @ts-expect-error type missing valueOptions
    chipColor={colDef.valueOptions.find(o => o.value === value)?.color}
    sx={{ width: '150px', cursor: 'pointer' }}
    label={formattedValue}
    selected={true}
  />
);


export const ChipSelectEditCell = ({ id, value, field, hasFocus, api, colDef }: GridRenderEditCellParams) => {
  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (hasFocus) ref.current?.focus();
  }, [hasFocus]);

  const handleChange = async (event: SelectChangeEvent) => {
    const isValid = await api.setEditCellValue({ id, field, value: event.target.value });

    if (isValid) {
      api.stopCellEditMode({ id, field });
    }
  };

  return (
    <CabDropdown
      inputRef={ref}
      value={value}
      onChange={handleChange}
      // @ts-expect-error type missing valueOptions
      options={colDef.valueOptions?.map(option => ({
        value: option.value,
        label: option.color
          ? <CabToggleChip chipColor={option.color} label={option.label} selected sx={{ width: 100 }} />
          : option.label
      })) || []}
      sx={{ width: '100%', marginLeft: 0.5, marginRight: 0.5, borderWidth: 0 }}
    />
  );
};


const StyledGroupButton = styled(CabButton, {label: "StyledGroupButton"})({
  justifyContent: 'start', 
  color: colors.black900, 
  paddingBottom: 8, 
  paddingTop: 8, 
  paddingLeft: 15, 
  paddingRight: 15, 
  width: '100%'
});


export default Table;
